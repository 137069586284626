import { Injectable } from '@angular/core'
import { Paginated } from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import authentication from '@feathersjs/authentication-client'
import { SERVER_URL } from 'src/environments/environment'
import { map, shareReplay } from 'rxjs/operators'
import { FeathersModel } from '../models/feathersModel'
import {
  defer,
  firstValueFrom,
  fromEventPattern,
  Observable,
  Subject,
} from 'rxjs'
import { User } from '../models/user'
import { AssistanceHooks } from '../feathersHooks/assistance.hooks'
import { AssistantAvailabilityHooks } from '../feathersHooks/assistantAvailability.hooks'
import { DataSubscriber, DataSubscriberMode } from './feathersDataSubscriber'
import { FeathersDataStore } from './feathersDataStore'
import { NavController } from '@ionic/angular'
import { ToasterService } from '../features/notification/toaster.service'
import {
  NewableFeathersModel,
  PartialWithURID,
} from '../shared/utils/typeUtils'
import {
  FeathersPaginatedDataStore,
  PaginatedWithAggregates,
} from './feathersPaginatedDataStore'
import { FeathersBaseDataStore } from './feathersBaseDataStore'
import { FeathersOneRecordDataStore } from './feathersOneRecordDataStore'
import { FeathersGenericDataStore } from './FeathersGenericDataStore'
import {
  ClientService,
  CustomMethod,
  feathers,
  FeathersService as FeathersBackendServiceImport,
} from '@feathersjs/client'
import { DefaultEventsMap } from '@socket.io/component-emitter'
import { io, Socket } from 'socket.io-client'
import makeClient from 'feathers-authentication-management/dist/client'
import { RecurrentFeathersModel } from '../models/RecurrentFeathersModel'
import { FeathersRecurrentDataStore } from './feathersRecurrentDataStore'
import { declareCustomMethodsOnBackendServices } from './FeathersServicesCustomMethodsDeclaration'
import { ConnectionAlertService } from './connectionAlert/connection-alert.service'
import { log } from '../shared/utils/rxjsUtils'
import { LoginService } from './login/login.service'

const defaultApiUrl = SERVER_URL

export abstract class FeathersRecord {
  id: number
}

export type FeathersQuery = { id?: number }
export type FeathersServiceEvent = 'created' | 'updated' | 'patched' | 'removed'
export type FeathersBackendService = FeathersBackendServiceImport
export type CustomMethods<T extends { [key: string]: CustomMethod }> = T
export type FeathersBackendServiceWithCustomMethods<
  T extends { [key: string]: CustomMethod },
> = ClientService & CustomMethods<T>

export type PaginatedStoreWithData<T extends FeathersModel, AggregateType> = {
  store: FeathersPaginatedDataStore<T, AggregateType>
  data$: Observable<PaginatedWithAggregates<T, AggregateType>>
}

@Injectable({
  providedIn: 'root',
})
export class FeathersService {
  private client = feathers() // feathers client
  private socket: Socket<DefaultEventsMap, DefaultEventsMap> // opened socket
  private feathersInit: Promise<void> // Promise that resolves after this.client is fully initialized and configured.
  public apiUrl = '' // endpoint url in use
  private authManagement
  private loginUrl = '/login'

  private reauth // Stored login credentials for reauth if session fails.
  private errorHandler = (error) => {
    if (this.reauth) {
      console.log('Feathers reauthentication-error, re-authenticating...')
      this.authenticate(this.reauth)
    } else {
      this.reauth = null
      this.client.removeListener('reauthentication-error', this.errorHandler)
      console.log(
        'DEBUG: Feathers reauthentication-error, but no credentials saved.',
      )
      this.navCtrl.navigateRoot(this.loginUrl, { animated: false })
    }
  }
  private TAG = 'FeathersService'
  private subscribers: DataSubscriber<any>[] = []
  private currentUser$: Observable<User>
  public readonly dataStoreHolder: FeathersDataStoreHolder =
    new FeathersDataStoreHolder()

  private logout$$ = new Subject<void>()
  public logout$ = this.logout$$.asObservable()

  constructor(
    private navCtrl: NavController,
    private toaster: ToasterService,
    private connectionAlertService: ConnectionAlertService,
    private loginService: LoginService,
  ) {
    this.feathersInit = this.initFeathers()
  }

  private initFeathers(): Promise<void> {
    // Note: we explicitly set <void> type on promise to avoid issue <https://github.com/Microsoft/TypeScript/issues/8516>.
    return new Promise<void>((resolve) => {
      // Add socket.io plugin
      this.socket = io(defaultApiUrl, {
        transports: ['websocket'],
        upgrade: false,
        forceNew: true,
      })

      this.socket.on('connect', async () => {
        // Emitted when socket succesfully connected
        console.log('socket connect')
        await this.connectionAlertService.setState(true)
      })
      this.socket.on('connect_error', async (err) => {
        // Emitted on any unsuccesful reconnect attempt
        console.log('socket connect_error', err)
        await this.connectionAlertService.setState(false)
      })
      this.socket.on('disconnect', async (reason, description) => {
        // Emitted when socket disconnected
        console.log('socket disconnect', reason, description)
        await this.connectionAlertService.setState(false)
      })
      this.socket.on('disconnect-deactivated', async (args) => {
        // Emitted when user (assistent) is deactivated therefore he will be logout
        console.log('socket deactivated', args.reason)
        await this.loginService.requestLogout(this, {
          reason: 'Váš účet byl deaktivován',
          isPositive: false,
        })
      })

      this.apiUrl = defaultApiUrl

      const socketClient = socketio(this.socket, {
        timeout: 40000,
      })

      this.client
        .configure(socketClient)
        .configure(authentication({ storage: localStorage }))

      this.client.hooks({
        error: {
          all: [this.handleUnauthenticated()],
        },
      })

      // Add authentication
      // this.client.configure(feathers.authentication({
      //   storage: window.localStorage
      // }))

      // Add hooks
      AssistanceHooks.registerHooks(this.client)
      AssistantAvailabilityHooks.registerHooks(this.client)

      declareCustomMethodsOnBackendServices(this.client, socketClient)

      this.authManagement = makeClient(this.client)

      console.log('Done initializing feathers client at %s.', defaultApiUrl)
      resolve()
    })
  }

  // Expose services
  public service(name: string) {
    return this.client.service(name)
  }

  public serviceWithCustomMethods<T extends { [key: string]: CustomMethod }>(
    name: string,
  ): ClientService & CustomMethods<T> {
    return this.client.service(name) as unknown as ClientService &
      CustomMethods<T>
  }

  // Expose authentication management - check if credentials.email is not registered
  public checkUnique(credentials): Promise<any> {
    return this.authManagement.checkUnique(credentials)
  }

  // Expose authentication management - request password reset for credentials.email
  public resetPasswordRequest(credentials): Promise<any> {
    const options = { preferredComm: 'email' } // passed to options.notifier, e.g. {preferredComm: 'email'}
    return this.authManagement.sendResetPwd(credentials, options)
  }

  // Expose authentication management - reset password to credentials.password using resetToken, received following resetPasswordRequest()
  public resetPassword(resetToken, credentials): Promise<any> {
    return this.authManagement.resetPwdLong(resetToken, credentials.password)
  }

  // Expose authentication management
  // Reset password to credentials.password using short resetToken, received following TODO: resetPasswordRequest()??
  public resetPasswordShort(resetToken, credentials): Promise<any> {
    return this.authManagement.resetPwdShort(
      resetToken,
      { email: credentials.email },
      credentials.password,
    )
  }

  // Expose authentication
  public authenticate(credentials?): Promise<any> {
    // ? if (this.feathersInit === undefined) {
    //     return this._authenticate(credentials);
    //   }
    // .then is called even if this.feathersInit has already been resolved.
    return this.feathersInit.then(() => {
      console.log('feathersInit', this.client)

      return this._authenticate(credentials)
    })
  }

  private _authenticate(credentials?): Promise<any> {
    this.reauth = null // Remove stored credentials
    this.client.removeListener('reauthentication-error', this.errorHandler)
    if (credentials && credentials.email) {
      credentials.strategy = credentials.strategy || 'local'
    }
    let reauth

    this.client.authenticate(credentials).then((response) => {
      console.log('auth response', response)
      const user = response.user
      console.log('User authenticated', user)
      this.client.set('user', user)
      this.client.on('reauthentication-error', this.errorHandler)
    })

    return this.client.get('authentication').then((authInfo) => {
      reauth = authInfo
      this.reauth = reauth

      console.log('Authentication information is', authInfo)
      return authInfo
    })
  }

  public reauthenticate() {
    console.log(this.TAG, 'reauthenticate')
    return this.client.reAuthenticate().then((authInfo) => {
      console.log('reauth', authInfo)
      this.client.set('user', authInfo.user)
      return authInfo
    })
  }

  // Expose registration
  public registerAndAuthenticate(credentials): Promise<User> {
    if (!credentials || !credentials.email || !credentials.password) {
      return Promise.reject(new Error('No credentials'))
    }
    this.reauth = null
    this.client.removeListener('reauthentication-error', this.errorHandler)
    return this.client
      .service('users')
      .create(credentials)
      .then((user) => {
        this.authenticate(credentials)
        return user
      })
  }

  public register(credentials): Promise<User> {
    if (!credentials || !credentials.email || !credentials.password) {
      return Promise.reject(new Error('No credentials'))
    }
    this.reauth = null
    this.client.removeListener('reauthentication-error', this.errorHandler)
    return this.client
      .service('users')
      .create(credentials)
      .then((user) => {
        return user
      })
  }

  public async getCurrentUserPromise(): Promise<User> {
    return firstValueFrom(this.getCurrentUser())
  }

  async getCurrentTenantNamePromise() {
    const currentUser = await this.getCurrentUserPromise()

    return (
      await firstValueFrom(
        this.findOnGenericServiceWithOneRecordStore<{
          tenant: { name: string }
        }>('users-tenants', {
          userId: currentUser.id,
        }),
      )
    ).tenant.name
  }

  public getCurrentUser(): Observable<User> {
    if (!this.currentUser$ && this.client.get('user').id) {
      this.currentUser$ = this.get(User, {
        id: this.client.get('user').id,
      }).pipe(
        log('getCurrentUser'),
        map((users) => users[0]),
        shareReplay({ bufferSize: 1, refCount: true }),
      )
    }

    return this.currentUser$
  }

  // Expose logout
  public async logout(): Promise<any> {
    this.reauth = null
    this.client.removeListener('reauthentication-error', this.errorHandler)
    this.currentUser$ = null
    this.logout$$.next()
    return this.client
      .logout()
      .then((result) => {
        return result
      })
      .catch((error) => {
        console.log(error)
        // return error; // Nobody cares about logout error.
      })
  }

  public getFeathersEventObservable(
    service: string,
    eventName: string,
  ): Observable<any> {
    return fromEventPattern(
      (handler) => this.service(service).on(eventName, handler),
      (handler) => this.service(service).off(eventName, handler),
    )
  }

  // ?private subscribers[]: DataSubscriber<any>[];
  public subscribe<T extends FeathersRecord>(
    service: string,
    query: any,
    cbData: (records: any) => void,
    cbErr: (err: any) => void,
    mode: DataSubscriberMode = DataSubscriberMode.SUBSCRIBE,
  ): DataSubscriber<T> {
    console.log(this.TAG, 'subscriber created', service, query)
    // console.log('subscribe', service)
    const subscriber = new DataSubscriber<T>(
      this.service(service),
      cbData,
      cbErr,
      mode,
    )
    subscriber.find(query, service).catch((err) => {
      cbErr(err)
    })

    this.subscribers.push(subscriber)
    console.log(this.TAG, 'subscribers', this.subscribers)

    return subscriber
  }

  // Will subscribe for one value and immediately unsubscribe
  public getOne<T extends FeathersModel>(
    recordClass: NewableFeathersModel<T>,
    query: any,
  ): Promise<T[]> {
    return firstValueFrom(this.get<T>(recordClass, query))
  }

  public findOnGenericServiceWithOneRecordStore<T>(
    serviceName: string,
    query: any = {},
  ): Observable<T> {
    return this.dataStoreHolder
      .getNewOneRecordDataStore<T>(this.service(serviceName))
      .initialize(query)
  }

  public findOnGenericServiceWithArrayRecordStore<T>(
    serviceName: string,
    query: any = {},
  ): Observable<T[]> {
    return this.dataStoreHolder
      .getGenericDataStore<T>(this.service(serviceName))
      .initialize(query)
  }

  public createOnGenericService(
    service: string,
    data: any,
    params?: any,
  ): Promise<any> {
    console.log('create', service, data, params)
    // record.id = null // Create should not try to set _id.
    return this.service(service).create(data, params)
  }

  /**
   * Find data by query and subscribe to all events of a model's service. Uses a datastore for client-side management of the fetched objects and their individual updating based on incoming events.
   * The datastore uses a factory to generate frontend classes from backend objects.
   *
   * Example usage
   * this.feathers.get(Client, {}).subscribe(data => {
   *    console.log('Got data from service "clients"', data)
   *  })
   *
   * @param recordClass put a class of your FE model here. The class has to have a 'serviceName: string' field which carries the name of its associated Feathers service
   * @param query usual feathers query
   */
  public get<T extends FeathersModel>(
    recordClass: NewableFeathersModel<T>,
    query: any = {},
  ): Observable<T[]> {
    console.log('get', recordClass.name, 'query:', query)
    return this.dataStoreHolder
      .getNewDataStore<T>(recordClass, this.service(recordClass.serviceName))
      .initialize(query)
  }

  /**
   * Find data by query and subscribe to all events of a model's service. Uses a datastore specifically tailored for recurrent data identified by URID.
   * The datastore uses a factory to generate frontend classes from backend objects.
   *
   */
  public getRecurrent<T extends RecurrentFeathersModel>(
    recordClass: NewableFeathersModel<T>,
    query: any = {},
  ): Observable<T[]> {
    console.log('getRecurrent', recordClass.name, 'query:', query)
    return this.dataStoreHolder
      .getNewRecurrentDataStore<T>(
        recordClass,
        this.service(recordClass.serviceName),
      )
      .initialize(query)
  }

  /**
   * Performs a one-off request to backend without subscription to service events.
   * Provides hydrated instance of a FeathersModel.
   * Not backed by datastore.
   */
  public async getPromise<T extends FeathersModel>(
    recordClass: NewableFeathersModel<T>,
    query: any = {},
  ): Promise<T[]> {
    const factory = new FeathersFrontendModelFactory(recordClass)
    const { data } = await this.service(factory.getServiceName()).find({
      query,
    })
    console.log('getPromise data', data)
    return data.map((d: T) => factory.generateModelInstanceFromFeathersData(d))
  }

  public getPaginated<T extends FeathersModel, AggregateT = never>(
    recordClass: NewableFeathersModel<T>,
    query: any = {},
  ): PaginatedStoreWithData<T, AggregateT> {
    const factory = new FeathersFrontendModelFactory(recordClass)
    const store = this.dataStoreHolder.getNewPaginatedDataStore<T, AggregateT>(
      this.service(factory.getServiceName()),
    )

    return {
      store,
      data$: store.initialize(query).pipe(
        map((data) => {
          data.data = data.data.map((d) =>
            factory.generateModelInstanceFromFeathersData(d),
          )
          return data
        }),
      ),
    }
  }

  public count(serviceName: string, query: any = {}): Promise<number> {
    // console.trace('count', serviceName, 'query:', query)
    query.$limit = 0
    return this.service(serviceName)
      .find({ query })
      .then((res: Paginated<never>) => res.total)
  }

  public upsert<T extends FeathersRecord>(
    service: string,
    record: T,
  ): Promise<T | T[]> {
    if (record.id) {
      return this.update<T>(service, record)
    }
    return this.create(service, record) as Promise<T>
  }

  public patchsert<T extends FeathersRecord>(
    service: string,
    record: T,
  ): Promise<T | T[]> {
    if (record.id) {
      return this.patch<T>(service, record)
    }
    return this.create(service, record) as Promise<T>
  }

  public create<T extends FeathersRecord>(service: string, record: T | T[]) {
    console.log('create', service, record)
    if (Array.isArray(record)) {
      // Creating multiple records
      return this.service(service).create(
        record.map((r) => {
          if (r.id) {
            console.error('ID should not be set when creating a record')
          }
          r.id = undefined // Create should not try to set id
          return r
        }),
      ) as Promise<T[]>
    } else {
      // Creating a single record
      record.id = undefined // Create should not try to set id
      return this.service(service).create(record) as Promise<T>
    }
  }

  /**
   * Posts a command to 'command' service with optional data payload.
   *
   * @param command - The command to be posted.
   * @param [data] - Optional payload data associated with the command. Default is undefined.
   * @param [params] - Optional parameters related to command processing, e.g. custom job handle.
   * @returns A Promise that resolves with the result of the command post.
   */
  public postCommand(command: string, data?: {}, params?: {}): Promise<any> {
    console.log('post command', command)
    return this.service('command').create({ command, payload: data, params })
  }

  public async customMethodWithFactory<
    T extends FeathersModel,
    L,
    CustMethods extends { [key: string]: CustomMethod },
  >(
    recordClass: NewableFeathersModel<T>,
    data: L,
    methodCall: (
      service: FeathersBackendServiceWithCustomMethods<CustMethods>,
      data: L,
    ) => Promise<T>,
  ) {
    const factory = new FeathersFrontendModelFactory(recordClass)
    // TODO: Generate custom service from service name based on registrations on feathersServicesCustomMethodsDeclaration registry
    // TODO: Call the custom method

    const result = await methodCall(
      this.serviceWithCustomMethods(factory.getServiceName()),
      data,
    )
    return factory.generateModelInstanceFromFeathersData(result)
  }

  public async createWithFactory(recordClass, record): Promise<any> {
    console.log('createWithFactory', recordClass.name, record)
    const factory = new FeathersFrontendModelFactory(recordClass)

    let newRecord = await this.create(factory.getServiceName(), record)
    newRecord = factory.generateModelInstanceFromFeathersData(newRecord)
    return newRecord
  }

  public createObservable<T extends FeathersRecord>(
    service: string,
    record: T,
  ): Observable<T> {
    record.id = null
    return defer(() => this.service(service).create(record) as Promise<T>)
  }

  public update<T extends FeathersRecord>(
    service: string,
    record: T,
  ): Promise<T | T[]> {
    if (!record.id) {
      return Promise.reject('_id must be set')
    }
    return this.service(service).update(record.id, record)
  }

  public async updateWithFactory(
    recordClass,
    record,
  ): Promise<typeof recordClass> {
    // console.trace('updateWithFactory', recordClass.name, record);
    const factory = new FeathersFrontendModelFactory(recordClass)

    console.log(factory.getServiceName())

    const resultingRecord = await this.update(factory.getServiceName(), record)
    return factory.generateModelInstanceFromFeathersData<typeof recordClass>(
      resultingRecord,
    )
  }

  public upsertWithFactory(recordClass, record): Promise<typeof recordClass> {
    if (record.id) {
      return this.updateWithFactory(recordClass, record)
    }
    return this.createWithFactory(recordClass, record)
  }

  public patchsertWithFactory(recordClass, record): Promise<any> {
    if (record.id) {
      return this.patchWithFactory(recordClass, record)
    }
    return this.createWithFactory(recordClass, record)
  }

  public patch<T extends FeathersRecord>(
    service: string,
    record: T,
  ): Promise<T | T[]> {
    console.log('patch', service, record)
    if (!record.id) {
      return Promise.reject('_id must be set')
    }
    return this.service(service).patch(record.id, record)
  }

  public async patchWithFactory(recordClass, record): Promise<any> {
    console.log('patchWithFactory', recordClass.name, record)
    const factory = new FeathersFrontendModelFactory(recordClass)

    const rec = await this.patch(factory.getServiceName(), record)
    return factory.generateModelInstanceFromFeathersData(rec)
  }

  public async patchByUrid<T extends RecurrentFeathersModel>(
    recordClass: NewableFeathersModel<T>,
    record: PartialWithURID<T>,
  ): Promise<any> {
    const factory = new FeathersFrontendModelFactory(recordClass)
    const res = await this.service(factory.getServiceName()).patch(
      record.urid,
      record,
    )
    return factory.generateModelInstanceFromFeathersData(res)
  }

  public async patchMultiByIds<T extends FeathersModel>(
    recordClass: NewableFeathersModel<T>,
    ids: number[],
    record: Partial<T>,
  ): Promise<T | T[]> {
    const factory = new FeathersFrontendModelFactory(recordClass)

    const res = await this.service(factory.getServiceName()).patch(
      null,
      record,
      { query: { id: { $in: ids } } },
    )
    console.log('patchedMulti', res)
    return res.map((resultRecord) =>
      factory.generateModelInstanceFromFeathersData(resultRecord),
    )
  }

  public async patchMultiByQuery<T extends FeathersModel>(
    recordClass: NewableFeathersModel<T>,
    query: any,
    record: Partial<T>,
  ): Promise<T[]> {
    const factory = new FeathersFrontendModelFactory(recordClass)

    const res = await this.service(factory.getServiceName()).patch(
      null,
      record,
      { query },
    )
    return res.map((resultRecord) =>
      factory.generateModelInstanceFromFeathersData(resultRecord),
    )
  }

  public remove<T extends FeathersRecord>(
    service: string,
    record: T,
  ): Promise<T> {
    console.log('remove', service, record)
    if (!record.id) {
      return Promise.reject('_id must be set')
    }
    return this.removeById(service, record.id)
  }

  public async removeById<T extends FeathersRecord>(
    service: string,
    id: number,
  ): Promise<T> {
    return await this.service(service).remove(id)
  }

  public async removeByUrid<T extends RecurrentFeathersModel>(
    recordClass: NewableFeathersModel<T>,
    urid: string,
  ): Promise<any> {
    console.log('removeByURID', recordClass.serviceName, urid)
    return await this.service(recordClass.serviceName).remove(urid)
  }

  private handleUnauthenticated() {
    return (context) => {
      const { params, error } = context

      // The socket is connected, but server is responding with
      // 401s, that means the token is expired, invalid, etc.
      // The other case is when the socket is not connected, then we
      // do not want to reload the page, we just want to wait for the socket
      // to reconnect and reauthenticate, this is typically due to temporary
      // loss of server connection
      if (error.name === 'NotAuthenticated') {
        console.log('notauth')
        this.reauth = null
        if (window.location.pathname !== '/login') {
          this.toaster.presentSimpleToast(
            'Vaše přihlášení vypršelo, přihlaste se prosím znovu.',
          )
          this.logout().then(() => {
            this.navCtrl.navigateRoot(this.loginUrl)
          })
        }
      }
    }
  }

  static unpaginateQuery(query: {}) {
    return { ...query, $limit: 20000, $skip: 0 }
  }
}

export class FeathersFrontendModelFactory<T extends FeathersModel> {
  constructor(private classToCreate: NewableFeathersModel<T>) {}

  public getServiceName<L extends FeathersRecord>(): string {
    if (!this.classToCreate.serviceName) {
      throw new Error(`Missing serviceName on class ${this.classToCreate.name}`)
    }
    return this.classToCreate.serviceName
  }

  public generateModelInstanceFromFeathersData<L extends FeathersModel>(
    data: Partial<T>,
  ): T {
    // console.log('generateModelInstanceFromFeathersData', data)
    const instance = new this.classToCreate()
    instance.updateFromProps(data)

    return instance
  }
}

export class FeathersDataStoreHolder {
  private dataStoreLastId = 0
  private activeStoreIds = new Set()
  private activeStores: FeathersBaseDataStore<any, any>[] = []
  private TAG = 'FeathersDataStoreCounter'

  getNextId() {
    this.dataStoreLastId++
    return this.dataStoreLastId
  }

  registerNewDataStore(store: FeathersBaseDataStore<any, any>) {
    console.log(`${this.TAG}#registerNewDataStore`, store)
    console.log(
      `${this.TAG}#registerNewDataStore all stores`,
      this.activeStores,
    )
    const newId = this.getNextId()
    this.activeStoreIds.add(newId)
    this.activeStores.push(store)
    return newId
  }

  unregisterDataStore(id: number) {
    this.activeStoreIds.delete(id)
    this.activeStores = this.activeStores.filter((store) => {
      if (id === store.id) {
        store.destroy()
        return false
      }
      return true
    })

    console.log(
      this.TAG,
      'DataStore unregistered, ActiveStores: ',
      this.activeStoreIds.size,
      this.activeStoreIds,
      this.activeStores,
    )
  }

  unregisterAllDataStores() {
    this.activeStoreIds.clear()
    this.activeStores.forEach((store) => store.destroy())
    this.activeStores = []
  }

  getNewDataStore<T extends FeathersModel>(
    recordClass: NewableFeathersModel<T>,
    service: any,
  ) {
    return new FeathersDataStore<T>(recordClass, service, this)
  }

  getNewRecurrentDataStore<T extends RecurrentFeathersModel>(
    recordClass: NewableFeathersModel<T>,
    service: any,
  ) {
    return new FeathersRecurrentDataStore<T>(recordClass, service, this)
  }

  getNewPaginatedDataStore<T extends FeathersModel, AggregateT>(service: any) {
    return new FeathersPaginatedDataStore<T, AggregateT>(service, this)
  }

  getNewOneRecordDataStore<T>(service: any) {
    return new FeathersOneRecordDataStore<T>(service, this)
  }
  getGenericDataStore<T>(service: any) {
    return new FeathersGenericDataStore<T>(service, this)
  }
}
