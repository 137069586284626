/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { FeathersModel } from './feathersModel'
import { Workgroup } from './workgroup'
import { Role, RoleGroup } from './functional/role'
import { Contract } from './contract'
import { Contact } from './contact'
import {
  coerceToArray,
  createIntlComparer,
  DEFAULT_LOCALES,
} from '../services/utils'
import { Client } from './client'
import { Assistant } from './assistant'
import {UsersTenant} from './usersTenant'

export interface Named {
  firstName: string
  lastName: string
}

export interface UserRoles {
  superadmin: boolean
  admin: boolean
  coordinator: boolean
  client: boolean
  assistant: boolean
  financial: boolean
  system: boolean
}

export class User extends FeathersModel implements Named {
  static serviceName = 'users'
  static intlSortComparer = createIntlComparer(DEFAULT_LOCALES, {
    sensitivity: 'accent',
  })

  workgroupId: number
  workgroup: Workgroup
  firstName: string
  lastName: string
  email: string
  password: string
  contacts: Contact[]
  color: string
  contracts: Contract[]
  config_subscribe_to_all_assistant_assistance_feedback: boolean
  config_allow_assistant_select_before_after: boolean
  roles: Partial<UserRoles>

  client?: Client
  assistant?: Assistant
  users_tenants?: UsersTenant[]

  get fullname() {
    return this.fullName()
  }

  public fullName() {
    return `${this.lastName} ${this.firstName}`
  }

  public fullNameReverse() {
    return `${this.firstName} ${this.lastName}`
  }

  static fullName(named: Named | Named[]) {
    const namedArr = coerceToArray(named)
    return namedArr.map((n) => `${n.lastName} ${n.firstName}`).join(', ')
  }

  turnSubresourcesIntoFrontendModels() {
    // TODO: turn contacts in Contact[]

    this.workgroup = (this.users_tenants ?? [])[0]?.workgroup
      ? Workgroup.createFromProps(Workgroup, (this.users_tenants ?? [])[0]?.workgroup)
      : this.workgroup

    if (this.assistant)
      this.assistant = FeathersModel.hydrate(Assistant, this.assistant)
    if (this.client) this.client = FeathersModel.hydrate(Client, this.client)

    if (this.contracts && this.contracts.length > 0) {
      const tempc = []
      for (const c of this.contracts) {
        tempc.push(Contract.createFromProps(Contract, c))
      }
      this.contracts = tempc

      const activeContract = Contract.computeCurrentlyActiveContract(
        this.contracts,
      )
      if (activeContract) {
        this.contracts.find((c) => activeContract.id === c.id).currentlyActive =
          true
      }
    }
  }

  public isSuperadmin() {
    return this.roles.superadmin
  }
  public isAdmin() {
    return this.roles.admin
  }
  public isCoordinator() {
    return this.roles.coordinator
  }
  public isAssistant() {
    return this.roles.assistant
  }
  public isClient() {
    return this.roles.client
  }
  public isFinancial() {
    return this.roles.financial
  }

  public isRole(role: Role) {
    return this.getRoles().includes(role)
  }

  public isInRoleGroup(roleGroup: Role[]) {
    for (const r of roleGroup) {
      if (this.isRole(r)) return true
    }
    return false
  }

  public isWorker() {
    return this.isInRoleGroup(RoleGroup.workers)
  }

  public getRoles() {
    const roles: Role[] = []
    if (this.isSuperadmin()) roles.push(Role.Superadmin)
    if (this.isAdmin()) roles.push(Role.Admin)
    if (this.isCoordinator()) roles.push(Role.Coordinator)
    if (this.isAssistant()) roles.push(Role.Assistant)
    if (this.isClient()) roles.push(Role.Client)
    if (this.isFinancial()) roles.push(Role.Financial)
    return roles
  }

  public getRolesHumanReadable() {
    return this.getRoles().join(', ')
  }

  setRoles(roles: Role[]): this {
    if (!roles) return this
    if (!this.roles) {
      this.roles = {
        admin: false,
        assistant: false,
        client: false,
        coordinator: false,
        financial: false,
        superadmin: false,
        system: false,
      }
    }

    for (const role of roles) {
      switch (role) {
        case Role.Admin:
          this.roles.admin = true
          return this
        case Role.Assistant:
          this.roles.assistant = true
          return this
        case Role.Client:
          this.roles.client = true
          return this
        case Role.Coordinator:
          this.roles.coordinator = true
          return this
        case Role.Superadmin:
          this.roles.superadmin = true
          return this
        case Role.Financial:
          this.roles.financial = true
          return this
      }
    }
  }

  removeContract(id: number) {
    this.contracts = this.contracts.filter((c: Contract) => c.id !== id)
  }

  getPrimaryPhoneContact() {
    if (!this.contacts) throw new Error(`No primary contact on user ${this.id}`)
    return this.contacts.find((c) => c.contactType === 'telefon' && c.primary)
  }

  static findByEmail(users: User[], email: string): User {
    return users.find((u) =>
      u.contacts?.some(
        (contact) =>
          contact.contactType === 'email' && contact.content === email,
      ),
    )
  }

  static sortByName(a: User, b: User) {
    return User.intlSortComparer(a.fullname, b.fullname)
  }
}
